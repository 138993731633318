<template>
    <main class="main">
      <div class="main-inner">
        <navbar />
        <appbar />
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
      <foot />
    </main>
</template>

<script>
export default {};
</script>

<style>
</style>